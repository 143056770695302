// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { withLayout } from '@better-bit-fe/base-ui';
import { useUserInfo } from '@better-bit-fe/base-provider';
import { getTmsMessages } from '@better-bit-fe/lang';
import { throttle } from '~/utils/index';
import useFrame from '~/hooks/useFrame';
import useScreen, { ScreenType } from '~/hooks/useScreen';
import { getLang, langList } from '@better-bit-fe/base-utils';

import { SymbolConfigProvider, useSymbolConfig } from '~/context/symbolConfig';
import Chat from '~/components/chat';
import H5HomePage from '~/components/HomePage/H5/home';
import PCHomePage from '~/components/HomePage/PC/home';

function Page() {
  useFrame();
  const { userInfo, isLogin } = useUserInfo();
  const { screenType, updateScreenType } = useScreen();
  useEffect(() => {
    const curLang: any = getLang();
    localStorage.setItem('LANG_KEY', curLang); // 保证其他页面也一致
  }, []);

  useEffect(() => {
    updateScreenType();
    const handler = throttle(updateScreenType, 1000);
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);
  // if (!screenType) {
  //   return;
  // }
  // 做响应式
  return (
    <div>
      <SymbolConfigProvider>
        <div className="page-wrapper">
          {screenType === ScreenType.SMALL ? <H5HomePage /> : <PCHomePage />}
          <Chat />
        </div>
      </SymbolConfigProvider>
    </div>
  );
}

/**
 * https://nextjs.org/docs/basic-features/data-fetching/get-static-props
 *
 * 只在服务端执行，加载的语言内容最终会被打包生成到html中
 *
 * @param ctx
 * @returns
 */
export const getStaticProps = async (ctx) => {
  const { locale, locales, defaultLocale } = ctx;
  const lc = locale || defaultLocale;
  const messages = await getTmsMessages({
    project: 'home-page', //要一一对应
    entry: import.meta.url,
    locale: lc,
    additions: ['title', 'description']
  });

  return {
    props: {
      locale: lc,
      locales,
      messages,
      title: messages.title,
      description: messages.description,
      ogImage: 'https://www.safex.hk/static/image/ogImage.jpeg'
    }
  };
};

export default withLayout(Page);
